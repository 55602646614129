<template>
  <div class="mobile-container">
    <mobileStatusBar title="重置手机号" />
    <div class="input-item">
      <div class="input"><input v-model="from.Phone" type="text" placeholder="请输入手机号" /></div>
    </div>
    <div class="input-item">
      <div class="input"><input v-model="from.CaptchaValue" type="text" placeholder="请输入验证码" /></div>
      <div class="code-img img-cover">
        <img v-if="captchaPath" :src="captchaPath" @click="handleGetCaptcha" />
      </div>
    </div>
    <div class="input-item">
      <div class="input"><input v-model="from.Vcode" type="text" placeholder="请输入短信验证码" /></div>
      <div class="send-code" :class="time > 0 ? 'not' : ''" @click="getMsg">{{ time > 0 ? `${time}s` : '发送验证码' }}</div>
    </div>
    <div class="flex-1"></div>
    <div class="btn-item public-btn" @click="handleSubmit">
      <van-loading v-if="submiting" color="#ffffff" />
      <span v-else>保存</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import mobileStatusBar from '../../components/mobileStatusBar.vue'

export default {
  name: "ResetPhone",
  components: {
    mobileStatusBar
  },
  data() {
    return {
      submiting: false,
      captchaPath: '',
      timer: null,
      time: 0,
      from: {
        Phone: '',
        CaptchaValue: '',
        Vcode: '',
      }
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    
  },
  mounted() {
    this.handleGetCaptcha()
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    handleGetCaptcha() {
      this.captchaPath = this.$HTTP.baseURL + '/common/captcha?v=' + (new Date().getTime())
    },
    async getMsg() {
      const { from, time, timer } = this
      if (time > 0 || timer != null) {
        return
      }
      if (!from.Phone) {
        this.$toast('请输入手机号')
        return
      }
      if (!from.CaptchaValue) {
        this.$toast('请输入您的验证码')
        return
      }
      var res = await this.$API.sendMsg.post({
        Phone: from.Phone,
        CaptchaValue: from.CaptchaValue,
      })
      if (res.error == 0) {
        this.setTimer()
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
        this.handleGetCaptcha()
      }
    },
    setTimer() {
      if (this.timer != null) {
        clearInterval(this.timer)
      }
      let t = 60
      this.time = t
      this.timer = setInterval(() => {
        t--
        this.time = t
        if (t <= 0) {
          clearInterval(this.timer)
        }
      }, 1000);
    },
    async handleSubmit() {
      const { from, submiting } = this
      if (submiting) {
        return
      }
      if (!from.Phone) {
        this.$toast('请输入手机号')
        return
      }
      if (!from.Vcode) {
        this.$toast('请输入您的验证码')
        return
      }
      this.submiting = true
      var res = await this.$API.updatePhone.post({
        Phone: from.Phone,
        Vcode: from.Vcode,
      })
      this.submiting = false
      if (res.error == 0) {
        this.$toast.success(res.info)
        this.$router.back()
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.input-item {
  width: 6.38rem;
  height: .88rem;
  margin-top: .32rem;
  padding: 0 .24rem;
  background-color: #fff;
  border-radius: .16rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .input {
    flex: 1;
  }
  .icon {
    width: .4rem;
    height: .4rem;
    margin-left: .24rem;
  }
  .code-img {
    cursor: pointer;
    width: 2rem;
    height: .6rem;
    margin-left: .24rem;
  }
  .send-code {
    cursor: pointer;
    margin-left: .24rem;
    font-size: .28rem;
    color: #276eff;
    &.not {
      color: #999;
    }
  }
}
.tips-item {
  margin-top: .24rem;
  width: 6.62rem;
  line-height: .34rem;
  color: #b3b3b3;
  font-size: .24rem;
}
.btn-item {
  cursor: pointer;
  margin-bottom: 1.6rem;
  width: 6.86rem;
  height: .88rem;
  border-radius: .12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .3rem;
  font-weight: bold;
  color: #fff;
}
</style>